<template>
  <div class="coupon-exchange-code-table">
    <el-table :data="displayData">
      <EmptyBlock slot="empty" />
      <el-table-column prop="code" label="兌換碼" align="center" />
      <el-table-column prop="totalStock" label="數量" align="center" />
      <el-table-column prop="source" label="來源" align="center" />
      <el-table-column prop="createdAt" label="建立時間" align="center" />
      <el-table-column prop="updatedAt" label="更新時間" align="center" />
      <el-table-column label="操作" align="center" prop="">
        <template slot-scope="scope">
          <TableEditBtnGroup
            @edit="clickHandler(scope.row, onEdit, 'edit')"
            @delete="clickHandler(scope.row, onDelete, 'delete')"
          />
        </template>
      </el-table-column>
    </el-table>
    <SerialCountInputModal
      v-if="modal.edit"
      mode="edit"
      :code="selectRow?.code"
      :totalStock="selectRow?.totalStock"
      :handleSubmit="editCouponExchangeSerial"
      @close="modal.edit = false"
    />

    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="modal.delete = false"
      @delete="deleteCouponExchangeSerial"
    />

    <BaseDialog
      v-if="modal.warning"
      width="566px"
      title="提醒"
      @confirm="confirmWarnDialog"
      @cancel="modal.warning = false"
      @close="modal.warning = false"
    >
      <div class="dialog-content">
        <p>該兌換券已有發放紀錄，若刪除或編輯既有兌換碼可能造成爭議</p>
        <p>請問您是否仍要編輯或刪除兌換碼？</p>
      </div>
    </BaseDialog>
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive } from 'vue'
import store from '@/store'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { get, map } from 'lodash'
import { formatDate } from '@/utils/date'
import SerialCountInputModal from '@/components/SerialCode/SerialCountInputModal.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'
import { UpdateCouponExchangeSerial, DeleteCouponExchangeSerial } from '@/api/coupon'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'

export default defineComponent({
  name: 'CouponExchangeCodeTable',
  components: { EmptyBlock, DeleteDialog, SerialCountInputModal, BaseDialog },
  props: {
    tableData: { type: Array, default: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const shopId = computed(() => store.getters.shop)
    const selectRow = ref(null)
    const modal = reactive({
      edit: false,
      delete: false,
      warning: false,
    })
    const displayData = computed(() => {
      return map(props.tableData, (item) => {
        const { id, source, code, totalStock, createdAt, updatedAt, CouponId, remainStock } = item
        return {
          id,
          source: sourceConfig[source],
          code,
          totalStock: totalStock || 1,
          createdAt: createdAt ? formatDate(createdAt) : ' - ',
          updatedAt: updatedAt ? formatDate(updatedAt) : ' - ',
          CouponId,
          remainStock: 1,
        }
      })
    })

    const moveType = ref(null)

    const sourceConfig = {
      manual: '單次新增',
      import: '大量匯入',
      generate: '系統生成',
    }

    const editCouponExchangeSerial = async (data) => {
      const { code, totalStock } = data
      const [, err] = await UpdateCouponExchangeSerial({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
        code,
        totalStock,
      })
      if (err) return window.$message.error(err)
      window.$message.success('更新成功！')
      modal.edit = false
      emit('refresh')
    }

    const onEdit = (row) => {
      selectRow.value = row
      modal.edit = true
    }

    const deleteCouponExchangeSerial = async () => {
      const [, err] = await DeleteCouponExchangeSerial({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      if (err) return window.$message.error(err)
      window.$message.success('刪除成功！')
      modal.delete = false
      emit('refresh')
    }

    const onDelete = async (row) => {
      selectRow.value = row
      modal.delete = true
    }

    const clickHandler = (row, callback, move) => {
      selectRow.value = row
      console.log(row)
      if (row.remainStock !== -1) {
        modal.warning = true
        moveType.value = move
        return
      }
      callback()
    }

    const confirmWarnDialog = () => {
      if (moveType.value === 'edit') {
        modal.edit = true
      }
      if (moveType.value === 'delete') {
        deleteCouponExchangeSerial()
      }
      modal.warning = false
    }

    return {
      displayData,
      onEdit,
      onDelete,
      modal,
      selectRow,
      deleteCouponExchangeSerial,
      editCouponExchangeSerial,
      confirmWarnDialog,
      clickHandler,
    }
  },
})
</script>

<style lang="postcss" scoped>
.dialog-content {
  @apply pb-[30px]
}
</style>
