<template>
  <div class="coupon-independent-code-block">
    <p class="card-title">兌換碼設定</p>
    <div class="pb-4">
      <div class="item-title">兌換碼生成方式</div>
      <el-button v-if="exchangeType ==='single'" plain @click="openSerialCodeModal(true, 'import')">大量匯入</el-button>
      <el-button v-if="exchangeType ==='single'" plain @click="openSerialCodeModal(true, 'system')">系統生成</el-button>
      <el-button plain @click="openSerialCodeModal(true, 'single')">單次新增</el-button>
    </div>
    <div>
      <div class="item-title">兌換碼列表</div>
      <FiltersContainer>
        <el-input
          v-model="search.code"
          style="max-width:400px"
          clearable
          placeholder="請輸入兌換碼搜尋"
          @keypress.enter.native="refresh(true)"
          @clear="refresh(true)"
        >
          <i slot="prefix" class="el-input__icon el-icon-search" />
        </el-input>
      </FiltersContainer>

      <section>
        <CouponExchangeCodeTable :tableData="tableData" @refresh="refresh" />
        <Pagination
          :curPage.sync="tableOptions.page"
          :pageLimit="tableOptions.pageLimit"
          :total="tableDataCount"
          @pageChange="refresh(false)"
        />
      </section>

      <SerialcodeImportModal
        v-if="modal.show && modal.modal === 'import'"
        :examplaeFile="serialCodeImportExample"
        :handleSubmit="createImportCouponExchangeSerial"
        @close="resetModal"
      />

      <SerialCodeSystemGernateModal
        v-if="modal.show && modal.modal === 'system'"
        :handleSubmit="createCouponExchangeSerialSystem"
        @close="resetModal"
      />

      <SerialCountInputModal
        v-if="modal.show && modal.modal === 'single'"
        :handleSubmit="createSingleCouponExchangeSerial"
        :showCount="exchangeType !== 'single'"
        @close="resetModal"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, onMounted, nextTick } from 'vue'
import CouponExchangeCodeTable from './CouponExchangeCodeTable.vue'
import { useTable } from '@/use/table'
import {
  GetCouponExchangeSerial,
  GetCouponExchangeSerialCount,
  CreateCouponExchangeSerial,
  CreateSystemCouponExchangeSerial,
} from '@/api/coupon'
import { serialCodeImportExample } from '@/config/couponExchange'

import SerialcodeImportModal from '@/components/SerialCode/SerialcodeImportModal.vue'
import SerialCodeSystemGernateModal from '@/components/SerialCode/SerialCodeSystemGernateModal.vue'
import SerialCountInputModal from '@/components/SerialCode/SerialCountInputModal.vue'

export default defineComponent({
  name: 'CouponIndependentCodeBlock',
  components: {
    CouponExchangeCodeTable,
    SerialCountInputModal,
    SerialCodeSystemGernateModal,
    SerialcodeImportModal,
  },
  props: ['exchangeType', 'couponId'],
  setup (props) {
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      search,
      fetchDataCount,
    } = useTable()

    const modal = reactive({
      show: false,
      modal: null,
    })

    const openSerialCodeModal = (isShow, modalType) => {
      modal.show = isShow
      modal.modal = modalType
    }

    const resetModal = () => {
      modal.show = false
      modal.modal = null
    }

    const createCouponExchangeSerial = async ({ source, serials }) => {
      const [, err] = await CreateCouponExchangeSerial({
        shopId: shopId.value,
        CouponId: props.couponId,
        source,
        serials,
      })
      if (err) throw err
    }

    const createSystemCouponExchangeSerial = async (payload) => {
      const [, err] = await CreateSystemCouponExchangeSerial({
        shopId: shopId.value,
        CouponId: props.couponId,
        amount: payload.amount,
        generators: payload.generators,
      })
      if (err) throw err
    }

    // 匯入新增
    const createImportCouponExchangeSerial = async (data) => {
      const serials = data.map((item) => {
        return {
          code: item,
          totalStock: 1,
        }
      })

      try {
        loading.value = true
        await createCouponExchangeSerial({ source: 'import', serials })
        window.$message.success('更新成功!')
      } catch (err) {
        window.$message.error(err)
      } finally {
        loading.value = false
        resetModal()
        refresh()
      }
    }

    //  單次新增
    const createSingleCouponExchangeSerial = async (data) => {
      const { code, totalStock } = data
      try {
        loading.value = true
        await createCouponExchangeSerial({
          source: 'manual',
          serials: [{ code, totalStock }],
        })
        window.$message.success('更新成功!')
      } catch (err) {
        window.$message.error(err)
      } finally {
        loading.value = false
        resetModal()
        refresh()
      }
    }

    const upperCaseCharset = 'ACDEFGHJKLMNPQRSTUVWXYZ'
    const lowerCaseCharset = 'abcdefghijkmnpqrstuvwxyz'
    const numberCharset = '0123456789'

    //  系統新增
    const createCouponExchangeSerialSystem = async (data) => {
      console.log('system', data)
      const { count, length, useLowerCase, useNumber, useUpperCase } = data
      const charset = [
        useUpperCase && upperCaseCharset,
        useLowerCase && lowerCaseCharset,
        useNumber && numberCharset,
      ].filter(Boolean).join('')

      try {
        loading.value = true
        await createSystemCouponExchangeSerial({
          amount: count,
          generators: [
            {
              length,
              charset,
            },
          ],
        })
        window.$message.success('更新成功!')
      } catch (err) {
        window.$message.error(err)
      } finally {
        loading.value = false
        resetModal()
        refresh()
      }
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        CouponId: props.couponId,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        code: search.code || undefined,
      }
      await Promise.allSettled([
        fetchData(GetCouponExchangeSerial, payload),
        fetchDataCount(GetCouponExchangeSerialCount, payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(async () => {
      await nextTick()
      if (props.couponId) {
        refresh()
      }
    })

    return {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      refresh,
      search,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
      modal,
      openSerialCodeModal,
      resetModal,
      createSingleCouponExchangeSerial,
      createCouponExchangeSerialSystem,
      createImportCouponExchangeSerial,
      serialCodeImportExample,
    }
  },
})
</script>

<style lang="postcss" scoped>
.item-title {
  @apply text-[#333] font-medium pb-2
}
</style>
